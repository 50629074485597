<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Онцлох бүтээгдэхүүн
      </h3>
      <div class="action-section"></div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <!-- <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            /> -->
          </div>
          <el-table :data="menus" size="mini" style="width: 100%">
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="outlet_name" label="Харилцагчийн нэр">
            </el-table-column>
            <el-table-column prop="name_mon" label="Нэр /монгол/">
            </el-table-column>
            <el-table-column prop="name_eng" label="Нэр /англи/">
            </el-table-column>
            <el-table-column prop="special_text" label="Онцлох">
            </el-table-column>
            <el-table-column prop="special_text_eng" label="Онцлох">
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-edit"
                  @click="update(scope.row)"
                  round
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="Устгах">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteSpecialMenu(scope.row._id)"
                  round
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="selMenu"
      :title="selMenu.name_mon"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" label-width="180px">
        <el-form-item label="Санал болгох эсэх">
          <el-switch v-model="selMenu.is_special"></el-switch>
        </el-form-item>
        <el-form-item label="Санал болгох монгол">
          <el-select
            v-model="selMenu.special_text"
            multiple
            filterable
            @change="changedSpecialTextMon"
            :disabled="!selMenu.is_special"
          >
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.name_mon"
              :value="item.name_mon"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Санал болгох англи">
          <el-select
            v-model="selMenu.special_text_eng"
            multiple
            filterable
            @change="changedSpecialTextEng"
            :disabled="!selMenu.is_special"
          >
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.name_eng"
              :value="item.name_eng"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirm">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Та энэхүү онцлох бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?"
      :visible.sync="deleteDialog"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="deleteClose()">Үгүй</el-button>
        <el-button size="mini" type="success" @click="deleteConfirmFunction()"
          >Тийм</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import service from "../helpers/services";
import { getUserName } from "../utils/auth";
export default {
  created() {
    this.getSpecials();
  },
  data() {
    return {
      selMenu: null,
      dialogVisible: false,
      menus: [],
      categories: [],
      deleteProductId: null,
      deleteDialog: false
    };
  },
  methods: {
    changedSpecialTextEng() {
      this.selMenu.special_text = [];
      this.categories.forEach(element => {
        this.selMenu.special_text_eng.forEach(el => {
          if (element.name_eng === el) {
            this.selMenu.special_text.push(element.name_mon);
          }
        });
      });
    },
    changedSpecialTextMon() {
      this.selMenu.special_text_eng = [];
      this.categories.forEach(element => {
        this.selMenu.special_text.forEach(el => {
          if (element.name_mon === el) {
            this.selMenu.special_text_eng.push(element.name_eng);
          }
        });
      });
    },
    confirm() {
      let self = this;
      if (!this.selMenu.is_special) {
        this.selMenu.special_text = "";
        this.selMenu.special_text_eng = "";
      }
      getUserName().then(name => {
        this.selMenu.user = name.attributes.email.split("@")[0];
        this.selMenu.id = this.selMenu._id;
        service.updateMenu(this.selMenu).then(response => {
          this.selMenu = null;
          this.dialogVisible = false;
          setTimeout(function() {
            self.getSpecials();
          }, 3000);
          this.$notify({
            title: "Амжилттай",
            message: response.message,
            type: "success"
          });
        });
      });
    },
    update(menu) {
      this.selMenu = menu;
      this.dialogVisible = true;
    },
    getSpecials() {
      service.getAllSuggestType().then(res => {
        this.categories = res;
        this.$forceUpdate();
      });
      service.getSpecialMenus().then(res => {
        this.menus = res.data.menus;
        this.$forceUpdate();
      });
    },
    deleteClose() {
      this.deleteDialog = false;
      this.deleteProductId = null;
    },
    deleteSpecialMenu(id) {
      this.deleteProductId = id;
      this.deleteDialog = true;
    },
    deleteConfirmFunction() {
      getUserName().then(name => {
        let payload = {
          _id: this.deleteProductId
        };
        payload.user = name.attributes.email.split("@")[0];
        service.deleteSpecialMenu(payload).then(data => {
          this.$message({
            type: "success",
            message: data.result
          });
          this.deleteClose();
        });
      });
    }
  }
};
</script>
